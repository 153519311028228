<template>
    <div>
        <div class="toolBar mBottom10">
            <el-cascader v-show="global.isAdmin" :props="global.orgTreeProps" :options="global.orgList" :show-all-levels="false" v-model="global.orgId" @change="handleChange"
                         size='small' style="margin-right: 20px"></el-cascader>
            <el-button plain size='small' class='fr' @click="refresh">刷新</el-button>
        </div>

        <el-card class="box-card">
            <el-table :data="recordList" size="small" class="maxWidth" stripe :height='tableHeight'>
                <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
                <el-table-column prop="phone" label="手机号码" width="100" align="center"></el-table-column>
                <el-table-column prop="createTime" label="反馈时间" width="200" align="center"></el-table-column>
                <el-table-column prop="content" label="反馈内容" align="center"></el-table-column>
            </el-table>
        </el-card>

        <div class="toolBar clearfix" style="margin-top: 10px">
            <pagi-nation :page='page' @sizeChange='sizeChange' @currentChange='currentChange'></pagi-nation>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'permissionConfig',
        data() {
            return {
                recordList: [],
                tableHeight: document.documentElement.clientHeight - 288,
                page: new this.Page(),

                medicalRecord: []
            }
        },
        computed: {
        },
        methods: {
            formatterStatus(row, column) {
                return this.getname('registeredStatus', row.registeredStatus);
            },
            formatMedicalType(row,column){
                return this.getname('medicalType', row.type);
            },
            showSearch() {
                if (!this.global.orgId.length) {
                    this.$message.warning('请先选择机构');
                    return;
                }
                this.isShowSearchRecordDlg = true;
            },
            handleChange(val) {
                if (val) {
                    localStorage.setItem('orgId',JSON.stringify(val));
                    this.getRecordList();
                    this.getDepartment();
                }
            },
            handleDetail(row) {
                this.$router.push({name: 'roleDetail', params: {id: row.id}});
            },
            sizeChange(val) {
                this.page.currentPage = 1;
                this.page.pageSize = val;
                this.getRecordList();
            },
            currentChange(val) {
                this.page.currentPage = val;
                this.getRecordList();
            },
            searchRecord() {
                this.page.reset();
                this.getRecordList();
                this.isShowSearchRecordDlg = false;
            },
            async getRecordList() {
                let params = {}
                let orgId = this.global.orgId[this.global.orgId.length-1];
                params.orgId = orgId;
                params.limit = this.page.pageSize;
                params.offset = (this.page.currentPage - 1) * this.page.pageSize;
                let res = await this.$http.post('/sys/suggest/getPage', params);
                if (res.state) {
                    for (let item of res.data.list) {
                        item.createTime = this.getDateTime(item.createTime);
                    }
                    this.recordList = res.data.list;
                    this.page.total = res.data.totalCount;
                }
            },
            async getDepartment() {
                let orgId = this.global.orgId[this.global.orgId.length-1];
                let param = {
                    orgId: orgId,
                    registeredType: 0
                };
                let res = await this.$http.post('/sys/getRegisteredHospitalDep', param);
                if (res.state) {

                    this.registeredDepart = [];
                    if (res.data) {
                        this.registeredDepart = res.data;
                    }
                }
            },
            refresh() {
                this.page.reset();
                this.getRecordList();
            },
            init() {
                if (this.global.orgId.length) {
                    this.getRecordList();
                }else {
                    setTimeout(() => {
                        this.init()
                    }, 200)
                }
            },
        },
        created() {
            this.init()
            /*this.getoptions('registeredStatus');
            this.getoptions('medicalType');*/
        },
        mounted() {
            window.onresize = () => {
                window.fullHeight = document.documentElement.clientHeight;
                this.tableHeight = window.fullHeight - 283;
            }
        }
    }

</script>

<style>
</style>
